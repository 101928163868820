body {
  font-family: Georgia, 'Times New Roman', Times, serif;
  background: #dddddd;
}

.App {
  background: #ffffff;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
}

.intakeForm h1,
h2,
h3 {
  text-align: center;
  text-transform: uppercase;
}
.page h1,
h2,
h3 {
  text-align: center;
  text-transform: uppercase;
}

.intakeForm input {
  border: 0;
  border-bottom: 1px solid #333333;
  width: 50%;
  font-family: inherit;
  font-weight: bold;
  font-size: inherit;
}

.intakeForm .checkbox {
  width: auto;
}

.page .inputArea {
  display: inline;
  font-weight: bold;
  border-bottom: 1px solid #333333;
}

.App {
  padding: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
